import React from "react"
import kebabCase from "lodash/kebabCase"
import { Helmet } from "react-helmet"
import { Link, graphql } from "gatsby"
import Layout from "../components/layout/Layout"

const title = "Tous les pays"

const CountriesPage = ({
  data: {
    allMdx: { group },
  },
}) => (
  <Layout>
    <Helmet title={title} />
    <h1>{title}</h1>
    <ul>
      {group.map(tag => (
        <li key={tag.fieldValue}>
          <Link to={`/countries/${kebabCase(tag.fieldValue)}`}>
            {tag.fieldValue} ({tag.totalCount})
          </Link>
        </li>
      ))}
    </ul>
  </Layout>
)

export default CountriesPage
export const pageQuery = graphql`
  query {
    allMdx(limit: 2000) {
      group(field: frontmatter___country) {
        fieldValue
        totalCount
      }
    }
  }
`
